export const iconsDefinitions = [
  { name: 'cube-fill', path: '../assets/3d-cube-fill.svg' },
  { name: 'cube-outline', path: '../assets/3d-cube-outline.svg' },
  { name: 'add', path: '../assets/add.svg' },
  { name: 'android', path: '../assets/android.svg' },
  { name: 'apple', path: '../assets/apple.svg' },
  { name: 'arrow-down', path: '../assets/arrow-down.svg' },
  { name: 'bar-chart-line', path: '../assets/bar-chart-line.svg' },
  { name: 'bin-outline', path: '../assets/bin-outline.svg' },
  { name: 'building', path: '../assets/building.svg' },
  { name: 'calendar-outline', path: '../assets/calendar-outline.svg' },
  { name: 'checkmark', path: '../assets/checkmark.svg' },
  { name: 'checkmark-fill', path: '../assets/checkmark-fill.svg' },
  { name: 'chevron-left', path: '../assets/chevron-left.svg' },
  {
    name: 'chevron-right-outline',
    path: '../assets/chevron-right-outline.svg',
  },
  { name: 'circle-outline', path: '../assets/circle-outline.svg' },
  { name: 'clock-outline', path: '../assets/clock-outline.svg' },
  { name: 'click-fill', path: '../assets/click-fill.svg' },
  { name: 'clock', path: '../assets/clock.svg' },
  { name: 'close', path: '../assets/close.svg' },
  { name: 'collapse-outline', path: '../assets/collapse-outline.svg' },
  { name: 'dashboard-fill', path: '../assets/dashboard-fill.svg' },
  { name: 'dashboard-outline', path: '../assets/dashboard-outline.svg' },
  { name: 'delete', path: '../assets/delete.svg' },
  { name: 'duplicate', path: '../assets/duplicate.svg' },
  { name: 'edit', path: '../assets/edit.svg' },
  { name: 'edit-line', path: '../assets/edit-line.svg' },
  { name: 'euro', path: '../assets/euro.svg' },
  { name: 'focus', path: '../assets/focus.svg' },
  { name: 'folder-fill', path: '../assets/folder-fill.svg' },
  { name: 'folder-outline', path: '../assets/folder-outline.svg' },
  { name: 'globe', path: '../assets/globe.svg' },
  { name: 'google', path: '../assets/google.svg' },
  { name: 'group', path: '../assets/group.svg' },
  { name: 'group-add', path: '../assets/group-add.svg' },
  { name: 'home', path: '../assets/home.svg' },
  { name: 'info', path: '../assets/info.svg' },
  { name: 'layers-fill', path: '../assets/layers-fill.svg' },
  { name: 'layers-outline', path: '../assets/layers-outline.svg' },
  { name: 'logo-DK', path: '../assets/logo-DK.svg' },
  { name: 'logo-login', path: '../assets/logo-login.svg' },
  { name: 'logo-name-fill', path: '../assets/logo-name-fill.svg' },
  { name: 'logo-name', path: '../assets/logo-name.svg' },
  { name: 'logo', path: '../assets/logo.svg' },
  { name: 'loop', path: '../assets/loop.svg' },
  { name: 'logout', path: '../assets/log-out.svg' },
  { name: 'menu', path: '../assets/menu.svg' },
  { name: 'microsoft', path: '../assets/microsoft.svg' },
  { name: 'notification', path: '../assets/notification.svg' },
  { name: 'parameters-outline', path: '../assets/parameters-outline.svg' },
  { name: 'pencil', path: '../assets/pencil.svg' },
  { name: 'rocket-fill', path: '../assets/rocket-fill.svg' },
  { name: 'rocket-outline', path: '../assets/rocket-outline.svg' },
  { name: 'search', path: '../assets/search.svg' },
  { name: 'store', path: '../assets/store.svg' },
  { name: 'show-password', path: '../assets/show-password.svg' },
  { name: 'sms-fill', path: '../assets/sms-fill.svg' },
  { name: 'store-fill', path: '../assets/store-fill.svg' },
  { name: 'table', path: '../assets/table-2.svg' },
  { name: 'user', path: '../assets/user.svg' },
  { name: 'user2-fill', path: '../assets/user2-fill.svg' },
  { name: 'user3-fill', path: '../assets/user3-fill.svg' },
  { name: 'users', path: '../assets/users.svg' },
  { name: 'warning', path: '../assets/warning.svg' },
  { name: 'burger-menu', path: '../assets/burger-menu.svg' },
  { name: 'grid-view', path: '../assets/grid-view.svg' },
  { name: 'download-csv', path: '../assets/download-csv.svg' },
];
