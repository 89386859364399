import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { concat, concatMap, EMPTY, Observable, of, switchMap } from 'rxjs';

const addAuthenticationHeader = (
  tokenGenerator: () => Observable<string | null>,
  request: HttpRequest<unknown>,
): Observable<HttpRequest<unknown>> =>
  tokenGenerator().pipe(
    switchMap((token) => {
      if (token) {
        const clonedRequest = request.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });
        return concat(of(clonedRequest), EMPTY);
      } else {
        return EMPTY;
      }
    }),
  );

export const defaultInterceptor: (
  baseUrl: string,
  tokenGenerator: () => Observable<string | null>,
) => (
  httpRequest: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => Observable<HttpEvent<unknown>> =
  (baseUrl: string, tokenGenerator: () => Observable<string | null>) =>
  (httpRequest: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const isInterceptedUrl = httpRequest.url.includes(baseUrl);
    const isPublicUrl = httpRequest.url.includes('public');
    if (isInterceptedUrl && !isPublicUrl) {
      return addAuthenticationHeader(tokenGenerator, httpRequest).pipe(
        concatMap((request) => next(request)),
      );
    }
    return next(httpRequest);
  };
