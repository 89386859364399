import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { errorHandlerActions } from './error-handler.actions';

export const error401$ = createEffect(
  (actions$ = inject(Actions)) => actions$.pipe(
    ofType(errorHandlerActions.throw401Error),
    tap(() => {
      console.log('401 from backend');
    }),
  ),
  { functional: true, dispatch: false },
);

export const error404$ = createEffect(
  (actions$ = inject(Actions)) => actions$.pipe(
    ofType(errorHandlerActions.throw404Error),
    tap(() => {
      console.log('404 from backend');
    }),
  ),
  { functional: true, dispatch: false },
);
