import { CommonModule } from '@angular/common';
import { Component, OnInit, effect } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { IdentityService } from '@dmc-ng/authentication';
import { userActions } from '@dmc-ng/data-access/user';
import { SidenavComponent } from '@dmc-ng/ui/sidenav';
import { Store } from '@ngrx/store';
import { asapScheduler } from 'rxjs';

import { iconsDefinitions } from './utils/icons-definitions';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, SidenavComponent],
  selector: 'dmc-console-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'Dekuple';

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private identityService: IdentityService, private store: Store) {
    effect(() => {
      const user = this.identityService.user();
      if(user) {
        asapScheduler.schedule(() => this.store.dispatch(userActions.setCurrentUser({ user })));
      } else {
        asapScheduler.schedule(() =>this.store.dispatch(userActions.resetCurrentUser()));
      }
    });
  }

  ngOnInit(): void {
    iconsDefinitions.forEach((icon) => this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)));
  }
}
