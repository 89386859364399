export const environment = {
  production: false,
  apiUrl: 'https://dev.api.dekuple-marketing.cloud/v1',
  publicUrl: 'https://dev.api.dekuple-marketing.cloud',
  csvUrl: 'https://dev.assets.dekuple-marketing.cloud/csv_templates',
  firebase: {
    apiKey: 'AIzaSyCNAstMcpfVmrn7cG0iOjHbMUdsQZMR8uM',
    authDomain: 'dev.auth.dekuple-marketing.cloud',
  },
};
